<!-- 
    @component
    
    EXPANDABLE SIDEBAR BUTTON
    
	@prop symbols - Array of symbols to display.
    @prop name - Button name.
	@prop icon - Button icon.
    @prop isSidebarExpanded - Whether or not the sidebar is expanded.
    @prop isSectionExpanded - Whether or not the section is expanded.
-->
<script lang="ts">
	import Button from './Button.svelte';

	export let name: string;
	export let icon: string;
	export let url: string;
	export let isSidebarExpanded: boolean;

	let isSectionExpanded: boolean = true;

	function handleClick() {
		isSectionExpanded = !isSectionExpanded;
	}
</script>

<!-- 
When sidebar is collapsed, we simply navigate to the button's url.
We conditionally pass props, rather than conditionally rendering a separate component, 
so the "fade-in" transition works correctly.
-->
<!-- TRIGGER BUTTON -->
<li class="!my-0 !p-0">
	<Button
		{name}
		{icon}
		href={isSidebarExpanded ? undefined : url}
		isExpanded={isSidebarExpanded}
		type={isSidebarExpanded ? 'button' : undefined}
		on:click={isSidebarExpanded ? handleClick : undefined}
	>
		<svelte:fragment slot="label-trail">
			<span class="ml-auto flex w-full items-center justify-end">
				<iconify-icon
					icon="solar:alt-arrow-right-outline"
					class="transform {isSectionExpanded ? 'rotate-90' : 'rotate-0'}"
				/>
			</span>
		</svelte:fragment>
	</Button>
</li>

<!-- DROPDOWN -->
{#if isSidebarExpanded && isSectionExpanded}
	<div data-testid="sidebar__expandable-section__content" class="relative flex h-full w-full">
		<slot />
	</div>
{/if}
