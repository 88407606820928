<script lang="ts">
	import { invalidate } from '$app/navigation';
	import { page } from '$app/stores';
	import { PUBLIC_STRIPE_CUSTOMER_PORTAL_LINK } from '$env/static/public';
	import { type PopupSettings, popup } from '@skeletonlabs/skeleton';

	import { tippy } from '../../../../actions';
	import { clerk } from '../../../../auth';
	import { getStores } from '../../../../stores';
	import UserAvatar from '../../elements/images/UserAvatar.svelte';

	const BUTTON_CLASSES = 'flex items-center p-2 w-full rounded-token hover:bg-component-hover';
	const LEAD_SPAN_CLASSES = 'flex items-center gap-x-2 text-surface-100';

	const { isExtensionStore } = getStores(['isExtensionStore']);

	export let avatarHeight: string = 'h-8';
	export let avatarWidth: string = 'w-8';
	export let avatarRounded: string = 'rounded-[6px]';
	export let avatarBorder: string | undefined = undefined;
	export let classes: string = '';
	export let trailClasses: string = '';

	let isDropdownVisible: boolean = false;

	// Init Popup (Skeleton)
	let focusClickPopup: PopupSettings = {
		event: 'click',
		middleware: {},
		placement: 'bottom',
		state: ({ state }) => {
			isDropdownVisible = state; // true === open, false === closed
		},
		target: 'avatar-dropdown'
	};

	const handleSignOut = async () => {
		if ($isExtensionStore) {
			window.location.href = import.meta.env.VITE_WEBAPP_URL + '/signout';
			return;
		}

		// Use window.location (instead of goto(...)) because we need to force
		// a full page reload to clear the session.
		await $clerk?.signOut();
		await invalidate('profile::data');
		window.location.href = '/signin';
	};
</script>

<!--  TRIGGER ELEMENT -->
<button
	aria-controls="avatar-dropdown"
	aria-expanded={!isDropdownVisible}
	data-testid="avatar-dropdown__trigger"
	type="button"
	class="group btn border border-transparent p-2 rounded-container-token hover:border-hover [&>*]:pointer-events-none {classes}"
	use:popup={focusClickPopup}
	use:tippy={{
		content: 'Click for account options',
		placement: 'right'
	}}
>
	<!-- LEAD -->
	<UserAvatar
		border={avatarBorder}
		height={avatarHeight}
		rounded={avatarRounded}
		width={avatarWidth}
	/>

	<!-- TRAIL -->
	<div class="flex w-full items-center justify-between gap-4 {trailClasses}">
		<div class="flex w-full flex-col items-start overflow-hidden text-left text-xs">
			<!-- 
			Prefer $page.data.profile (over $profileStore) because it is available sooner,
			thereby reducing layout shifts.
			-->
			<span class="w-full truncate font-bold text-surface-50">
				{$page.data.profile?.name || $page.data.profile?.username || 'loading...'}
			</span>
			<span class="w-full truncate text-on-surface-token">
				@{$page.data.profile?.username ?? 'loading...'}
			</span>
		</div>

		{#if isDropdownVisible}
			<iconify-icon icon="solar:alt-arrow-down-linear" />
		{:else}
			<iconify-icon icon="solar:alt-arrow-right-linear" />
		{/if}
	</div>
</button>

<!--  DROPDOWN ELEMENT -->
<div
	aria-hidden={isDropdownVisible}
	data-popup="avatar-dropdown"
	data-testid="avatar-dropdown"
	class="z-20 w-60 overflow-hidden border border-base bg-component p-1 shadow-md shadow-black rounded-container-token tablet:w-56"
>
	<ul class="list w-full">
		{#if $page.data.isSuperuser}
			<li>
				<a
					data-testid="avatar-dropdown__backoffice-link"
					href="/backoffice"
					class={BUTTON_CLASSES}
				>
					<span class={LEAD_SPAN_CLASSES}>
						<iconify-icon inline icon="solar:buildings-outline" />
						<span> Back office </span>
					</span>
					<!-- <p class={TRAILING_P_CLASSES}>
						Manage the platform, including users, posts, and more.
					</p> -->
				</a>
			</li>

			<hr class="-mx-2 border-t !border-base" />
		{/if}

		<!-- 
			Use $page.data.profile (rather than $profileStore) because it will ALWAYS be loaded and set on mount
			where as $profileStore does not get set until later in the lifecycle.
			-->
		{#if $page.data.profile}
			<!-- <li>
				<a
					data-testid="avatar-dropdown__profile-link"
					href="/profile"
					type="button"
					class={BUTTON_CLASSES}
				>
					<span class={LEAD_SPAN_CLASSES}>
						<iconify-icon inline icon="solar:user-circle-linear" />
						<span> View profile </span>
					</span>
				</a>
			</li> -->

			<li>
				<button
					data-testid="avatar-dropdown__account-button"
					type="button"
					class={BUTTON_CLASSES}
					on:click|stopPropagation={() => {
						window.Clerk?.openUserProfile();
					}}
				>
					<span class={LEAD_SPAN_CLASSES}>
						<iconify-icon inline icon="solar:settings-linear" />
						<span> Manage account </span>
					</span>
					<!-- <p class={TRAILING_P_CLASSES}>Manage your account email, password, and more.</p> -->
				</button>
			</li>

			<li>
				<a
					data-testid="avatar-dropdown__billing-link"
					href={PUBLIC_STRIPE_CUSTOMER_PORTAL_LINK}
					rel="noreferrer noopener"
					target="_blank"
					class={BUTTON_CLASSES}
				>
					<span class={LEAD_SPAN_CLASSES}>
						<iconify-icon inline icon="solar:card-outline" />
						<span> Manage subscription </span>
					</span>
					<!-- <p class={TRAILING_P_CLASSES}>Manage subscription and payment methods.</p> -->
				</a>
			</li>
		{/if}

		<hr class="-mx-2 border-t !border-base" />

		<li>
			<button
				data-testid="avatar-dropdown__signout-button"
				type="button"
				class={BUTTON_CLASSES}
				on:click={handleSignOut}
			>
				<span class="!text-red-500 {LEAD_SPAN_CLASSES}">
					<iconify-icon inline icon="solar:logout-2-linear" />
					<span> Log out </span>
				</span>
				<!-- <p class={TRAILING_P_CLASSES}>Sign out of your account.</p> -->
			</button>
		</li>
	</ul>
</div>
