<script lang="ts">
	export let sectionTitle: string;
	export let testid: string;
	export let listClasses: string = 'list';
</script>

<div data-testid={testid}>
	<p class="mb-2 px-4 text-sm font-bold">{sectionTitle}</p>

	<ul class={listClasses}>
		<slot />
	</ul>
</div>
