/* eslint-disable perfectionist/sort-objects */
/* eslint-disable @typescript-eslint/no-empty-function */
import type { Me } from '@tickrr/db';

import type { DrawerStore, ModalStore } from '../../../../../../types/index.js';

import { openFeedbackModal } from '../../../../modals/feedback-modal/index.js';

export type StandardSidebarButton = {
	arialabel: string;
	handler?: (props: {
		drawerStore: DrawerStore;
		isExtension: boolean;
		isMobile: boolean;
		modalStore: ModalStore;
		profile?: Me | null;
	}) => void;
	icon: string;
	isHidden?: boolean;
	name: string;
	url?: string | undefined; // Used to track whether item is currently active/selected.
	urlProps?: {
		openNewTab?: boolean;
	};
};

export type ExpandableSidebarButton = {
	arialabel: string;
	buttons: Omit<StandardSidebarButton, 'icon'>[];
	icon: string;
	name: string;
	symbols: string[];
	url: string; // Used when sidebar is collapsed.
};

export type SidebarButton = ExpandableSidebarButton | StandardSidebarButton;

export type SidebarButtonData = {
	manage: SidebarButton[];
	misc: StandardSidebarButton[];
	research: SidebarButton[];
	social: SidebarButton[];
};

export const getSidebarButtonData = (): SidebarButtonData => {
	return {
		research: [
			// {
			// 	arialabel: 'Navigate to Home page',
			// 	icon: 'fluent:home-24-regular',
			// 	name: 'Home',
			// 	url: '/home'
			// },
			{
				arialabel: 'Navigate to Charts page',
				icon: 'tabler:chart-line',
				name: 'TickrrCharts &trade;',
				url: '/charts',
				symbols: ['TSLA', 'AAPL', 'NVDA']
			},
			{
				arialabel: 'Navigate to Markets page',
				icon: 'solar:globus-outline',
				name: 'Markets',
				url: '/markets'
			},
			{
				arialabel: 'Navigate to Stock Screener',
				icon: 'solar:target-outline',
				name: 'Screener',
				url: '/screener'
			},
			{
				arialabel: 'Navigate to Search page',
				icon: 'solar:magnifer-outline',
				name: 'Search',
				url: '/search'
			}
			// {
			// 	name: 'Tickrr™ Blog',
			// 	arialabel: 'Navigate to Blog page',
			// 	url: '/blog',
			// 	icon: IconArticle,
			// 	handler: () => console.log('Navigate to Blog page.')
			// }
			//
		],

		social: [
			{
				name: 'News',
				arialabel: 'Browse news pages',
				icon: 'solar:documents-outline',
				url: '/news'
			},
			{
				arialabel: 'Navigate to Forums page',
				icon: 'solar:list-outline',
				name: 'Forums',
				url: '/posts'
			}
		],

		// 	{
		// 		name: 'Reports',
		// 		arialabel: 'Navigate to Reports page',
		// 		icon: IconHome2,
		// 		url: '/reports',
		// 		handler: () => {}
		// 	}
		// contribute: [
		// 	{
		// 		arialabel: 'Submit new link to be displayed in the feed',
		// 		handler: openSubmitArticleComponent,
		// 		isLink: false,
		// 		url: '/new?t=link',
		// 		icon: 'solar:link-square-linear',
		// 		name: 'Submit article'
		// 	},
		// 	{
		// 		arialabel: 'Create a new post',
		// 		handler: openNewPostComponent,
		// 		isLink: false,
		// 		url: '/new?t=post',
		// 		icon: 'solar:pen-new-square-linear',
		// 		name: 'Create post'
		// 	}
		// 	// {
		// 	// 	name: 'Suggest new source',
		// 	// 	arialabel: 'Suggest a new news source for us to begin including in the feed',
		// 	// 	icon: IconSourceCode,
		// 	// 	handler: () => console.log('submit text')
		// 	// }
		// ],
		manage: [
			{
				arialabel: 'Navigate to Alerts',
				icon: 'solar:bell-linear',
				name: 'Alerts',
				url: '/alerts'
			},
			{
				arialabel: 'Navigate to Watchlist',
				icon: 'solar:eye-outline',
				name: 'Watchlist',
				url: '/watchlist'
			},
			{
				arialabel: 'Navigate to Bookmarks',
				icon: 'solar:bookmark-outline',
				name: 'Bookmarks',
				url: '/bookmarks'
			},
			{
				arialabel: 'Navigate to Notifications',
				icon: 'solar:notification-unread-linear',
				name: 'Notifications',
				url: '/notifications'
			}
			// {
			// 	arialabel: 'Navigate to Reading History',
			// 	handler: () => {},
			// 	isLink: true,
			// 	icon: 'solar:history-linear',
			// 	name: 'Reading history',
			// 	url: '/history'
			// },
			// {
			// 	arialabel: 'Navigate to Profile',
			// 	isLink: true,
			// 	handler: () => {},
			// 	icon: 'solar:user-circle-linear',
			// 	name: 'Profile',
			// 	url: '/profile'
			// }
		],
		misc: [
			{
				arialabel: 'Email support',
				icon: 'solar:danger-triangle-outline',
				name: 'Support',
				url: 'mailto:support@tickr.io?subject=Tickrr.io Support Request'
			},
			{
				arialabel: 'Give feedback',
				icon: 'solar:letter-outline',
				name: 'Feedback',
				handler: ({ drawerStore, modalStore }) => {
					drawerStore.close();
					openFeedbackModal(modalStore);
				}
			},
			{
				arialabel: 'Navigate to Changelog',
				icon: 'solar:code-file-linear',
				name: 'Changelog',
				url: '/changelog'
			}
			// {
			// 	arialabel: 'Install Extension',
			// 	isLink: true,
			// 	handler: () => {},
			// 	isHidden: isExtension, // No need to display if the App is already installed.
			// 	// url: 'https://chromewebstore.google.com/detail/tickrrio-one-click-stock/bfnckokemelmapfaefmpbgcaaahojpfl',
			// 	url: '/extension/install',
			// 	icon: 'solar:laptop-linear',
			// 	name: 'Install extension',
			// 	urlProps: {
			// 		openNewTab: true
			// 	}
			// },
			// {
			// 	arialabel: 'Install on mobile',
			// 	isLink: true,
			// 	handler: () => {},
			// 	isHidden: isPwa, // No need to display if the App is already installed.
			// 	url: '/install',
			// 	icon: 'solar:smartphone-vibration-outline',
			// 	name: 'Install on mobile'
			// }
		]
	};
};
