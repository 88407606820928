<script lang="ts">
	import { page } from '$app/stores';
	import { getDrawerStore, getModalStore } from '@skeletonlabs/skeleton';

	import { getStores } from '../../../../../../stores';
	import SidebarButton from './Button.svelte';
	import ExpandableButtonGroup from './ButtonGroup.Expandable.svelte';
	import SidebarInstrument from './SidebarInstrument.svelte';
	import { type SidebarButton as SidebarButtonType } from './buttons';

	const drawerStore = getDrawerStore();
	const modalStore = getModalStore();
	const { isExtensionStore } = getStores(['isExtensionStore']);

	export let buttons: SidebarButtonType[];
	export let label: null | string = null;
	export let isSidebarExpanded: boolean;
	export let isMobileDrawer: boolean;
	export let classes: string = '';
	export let buttonClasses: string = '';
</script>

<div class="{classes} mt-1">
	{#if label}
		<div class="flex items-center gap-x-1">
			<span
				class="mb-1 block pl-2 text-xs font-bold tracking-wider text-surface-50"
				class:hidden={!isSidebarExpanded && !isMobileDrawer}>{label}</span
			>
			<!-- <hr class="w-full !border-dashed !border-surface-400" /> -->
		</div>
	{/if}

	<ul class="list">
		{#each buttons as button (button.name)}
			{@const isExpandable = 'buttons' in button || 'symbols' in button}
			{#if isExpandable}
				<ExpandableButtonGroup
					name={button.name}
					icon={button.icon}
					{isSidebarExpanded}
					url={button.url}
				>
					{#if button.symbols.length > 0}
						<div class="flex w-full flex-col gap-y-0 pl-6">
							<a
								data-testid="sidebar__new-chart-button"
								href="/charts"
								class="btn mb-1 w-full bg-surface-700 py-2 text-xs duration-0 hover:bg-component-hover"
							>
								<iconify-icon icon="tabler:plus"></iconify-icon>
								<span>New chart</span>
							</a>
							{#each button.symbols as symbol (symbol)}
								<SidebarInstrument {symbol} />
							{/each}
						</div>
					{:else if 'buttons' in button}
						<div class="ml-8 w-full">
							<svelte:self
								{buttonClasses}
								buttons={button.buttons}
								{isMobileDrawer}
								{isSidebarExpanded}
							/>
						</div>
					{/if}
				</ExpandableButtonGroup>
			{:else}
				<li class="!my-0 !p-0">
					<SidebarButton
						name={button.name}
						icon={button.icon}
						arialabel={button.arialabel}
						classes={buttonClasses}
						href={button.url}
						isExpanded={isSidebarExpanded || isMobileDrawer}
						isHidden={button.isHidden}
						on:click={() => {
							if (!button.handler) {
								return;
							}

							button.handler({
								drawerStore,
								isExtension: $isExtensionStore,
								isMobile: isMobileDrawer,
								modalStore,
								profile: $page.data.profile
							});
						}}
					/>
				</li>
			{/if}
		{/each}
	</ul>
</div>
