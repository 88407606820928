<script context="module" lang="ts">
	// !! command-menu-item class is NECEESSARY for keyboard navigation !!
	export const COMMAND_MENU_ITEM_CLASS = 'command-menu-item';
</script>

<script lang="ts">
	export let icon: null | string = null;
	export let classes: string = '';
	export let valueClasses: string = '';
	export let url: string | undefined = undefined;
</script>

<li>
	<!-- svelte-ignore a11y-no-static-element-interactions -->
	<svelte:element
		this={url ? 'a' : 'button'}
		data-testid="command-menu__item"
		href={url ?? undefined}
		type={url ? undefined : 'button'}
		class="
		group/suggestion
		{COMMAND_MENU_ITEM_CLASS} 
		flex w-full justify-between
		px-4 py-2
		text-left
		hover:variant-filled-surface
		focus:bg-component
		focus:!outline-0
		focus:!outline-transparent
		{classes}
		"
		on:click
	>
		<div class="flex items-center">
			{#if $$slots.img}
				<slot name="img"><!-- optional fallback --></slot>
			{:else if icon}
				<iconify-icon inline {icon} class="mr-2" />
			{/if}
			<div>
				<p
					class="line-clamp-1 text-surface-50 group-hover/suggestion:underline {valueClasses}"
				>
					<slot name="value"><!-- optional fallback --></slot>
				</p>
				{#if $$slots['secondary-value']}
					<p class="line-clamp-1">
						<slot name="secondary-value"><!-- optional fallback --></slot>
					</p>
				{/if}
			</div>
		</div>
		<slot name="trail" />
	</svelte:element>
</li>
