<script context="module" lang="ts">
	import type { DrawerSettings } from '@skeletonlabs/skeleton';

	/**
	 * @see https://www.skeleton.dev/utilities/drawers.
	 */
	export const mobileSidebarDrawerSettings: DrawerSettings = {
		border: 'border-r border-surface-300/25',
		// This value must coincide with the animation-transition-duration
		// property set in AppShell.svelte.
		duration: 300,
		height: 'h-screen',
		id: 'mobile-sidebar',
		regionDrawer: 'pr-0 pt-2 pb-2 !overflow-visible', // overflow-visible to ensure that AvatarDropdown does not clip right border
		rounded: 'rounded-none',
		width: 'w-[16rem]'
	};
</script>

<script lang="ts">
	import { getStores } from '../../../../stores';
	import CommonSidebar from './_internal/sidebar/CommonSidebar.svelte';
	import NameAndLogo from './NameAndLogo.svelte';

	const { isPwaStore } = getStores(['isPwaStore']);

	// Props
</script>

<div data-testid="sidebar__mobile" class="flex h-full flex-col">
	<header
		class="mb-2 flex h-[60px] w-full !shrink-0 items-center justify-center border-b border-surface-500"
	>
		<a href="/">
			<NameAndLogo displayBetaTag isMobileDrawerLogo />
		</a>
	</header>

	<CommonSidebar isMobileDrawer isPwa={$isPwaStore} isSidebarExpanded />
</div>
