<script lang="ts">
	import { page } from '$app/stores';
	import { ProgressRadial, getModalStore } from '@skeletonlabs/skeleton';
	import { createMutation } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';

	const modalStore = getModalStore();

	let content: string = '';
	let isSubmitted: boolean = false;
	let isInvalidSubmission: boolean = false;

	const mutation = createMutation({
		mutationFn: async () => {
			return await trpc($page).email.sendFeedbackEmail.mutate({ content });
		},
		onError: (err) => {
			logger.error({ err }, '[feedback-modal] An error occurred while submitting feedback.');
		},
		onSettled: () => {
			isSubmitted = true;
		}
	});

	const handleSubmit = (
		e: SubmitEvent & {
			currentTarget: EventTarget & HTMLFormElement;
		}
	) => {
		e.preventDefault();

		if (content.length === 0) {
			isInvalidSubmission = true;
			return;
		}
		isInvalidSubmission = false;
		$mutation.mutate();
	};
</script>

<div data-testid="feedback-modal__container" class="w-full px-2">
	<div
		data-testid="feedback-modal"
		class="w-modal relative mx-auto w-full border border-base bg-surface-800 p-4 rounded-container-token"
	>
		<!-- CLOSE BUTTON -->
		<button
			aria-label="Close modal"
			type="button"
			class="btn-icon btn-icon-sm absolute right-2 top-2 rounded-md border border-base !bg-surface-600 hover:border-base"
			on:click={() => modalStore.clear()}
		>
			<iconify-icon icon="tabler:x"></iconify-icon>
			<span class="sr-only"> Close modal </span>
		</button>
		<!-- FORM  -->
		{#if !isSubmitted}
			<form on:submit={handleSubmit}>
				<div class="mb-3 flex flex-col gap-3">
					<span class="font-bold text-surface-50">
						What can we do to improve Tickrr?
					</span>
					<span class="">
						We appreciate any and all feedback you can provide. We'll use it to improve
						Tickrr and make it even better for you!
					</span>
					<textarea
						placeholder="What can we do to improve Tickrr?"
						rows="8"
						class="textarea w-full"
						class:!border-error-500={isInvalidSubmission}
						bind:value={content}
					/>
					{#if isInvalidSubmission}
						<span class="text-error-500"> Feedback must be one word or longer. </span>
					{/if}
				</div>

				<footer class="flex justify-end">
					<button type="submit" class="variant-filled btn font-[500]">
						{#if $mutation.isLoading}
							<ProgressRadial height="h-6" width="w-6" />
						{:else}
							<span> Submit </span>
						{/if}
					</button>
				</footer>
			</form>
		{:else}
			<div class="flex flex-col items-center justify-center gap-4 py-4">
				<iconify-icon
					icon="solar:check-circle-outline"
					height="80px"
					class="text-primary-500"
				/>
				<div class="space-y-3">
					<p class="text-center text-xl font-bold text-surface-50">
						Thanks for your response!
					</p>
					<p class="max-w-md">
						We sincerely appreciate your feedback. Our team will review your message
						shortly, and we may reach out to over email if we have any questions or need
						any additional information.
					</p>
				</div>
				<button
					type="button"
					class="variant-filled btn mx-auto w-full max-w-xs font-[500]"
					on:click={() => modalStore.clear()}
				>
					<span>Close</span>
				</button>
			</div>
		{/if}
	</div>
</div>
