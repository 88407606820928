<script lang="ts">
	import { page } from '$app/stores';
	import { getDrawerStore, getModalStore } from '@skeletonlabs/skeleton';
	import _ from 'lodash';

	import type { StandardSidebarButton } from './buttons.ts';

	import { getStores } from '../../../../../../stores';

	const modalStore = getModalStore();
	const drawerStore = getDrawerStore();

	const { isExtensionStore } = getStores(['isExtensionStore']);

	export let buttons: StandardSidebarButton[];
	export let label: null | string = null;
	export let isSidebarExpanded: boolean;
	export let isMobileDrawer: boolean;
	export let classes: string = '';
</script>

<div class="{classes} mt-1">
	{#if label}
		<div class="flex items-center gap-x-1">
			<span
				class="mb-1 block pl-2 text-xs font-bold tracking-wider text-surface-400"
				class:hidden={!isSidebarExpanded && !isMobileDrawer}>{label}</span
			>
		</div>
	{/if}

	<ul class="flex gap-1" class:flex-col={isSidebarExpanded === false}>
		{#each buttons as button (button.name)}
			<li class="!my-0 w-full !p-0">
				<!-- eslint-disable-next-line svelte/valid-compile -->
				<!-- svelte-ignore a11y-no-static-element-interactions -->
				<svelte:element
					this={button.url ? 'a' : 'button'}
					id={_.kebabCase(button.name)}
					name="sidebar-button"
					aria-label={button.arialabel}
					data-testid="sidebar__{_.kebabCase(button.name)}-button"
					data-tippy-content={button.name}
					data-tippy-placement="top"
					href={button.url ?? undefined}
					rel={button.url && button.urlProps?.openNewTab
						? 'noopener noreferrer'
						: undefined}
					tabindex={button.url ? -1 : 0}
					target={button.urlProps?.openNewTab ? '_blank' : undefined}
					type={button.url ? undefined : 'button'}
					class="
                    btn
                    my-0
                    w-full
                    rounded-lg
                    border
                    border-transparent
                    px-2
					py-2
                    text-surface-300
                    !duration-0
                    hover:bg-component-hover
					hover:text-surface-50
					{isSidebarExpanded ? 'bg-surface-700' : ''}
                    {classes}"
					on:click={() => {
						if (!button.handler) {
							return;
						}

						button.handler({
							drawerStore,
							isExtension: $isExtensionStore,
							isMobile: isMobileDrawer,
							modalStore,
							profile: $page.data.profile
						});
					}}
				>
					<!-- ICON -->
					<iconify-icon inline icon={button.icon} height="1em" width="auto" />

					<!-- CONTENT -->
					<span class="sr-only">{button.name}</span>
				</svelte:element>
			</li>
		{/each}
	</ul>
</div>
