<script context="module" lang="ts">
	const MAX_HISTORY_ITEMS = 3;
	const FUZZY_DISTANCE = 3; // edit distances; see https://lunrjs.com/guides/searching.html

	/**
	 * Returns a fuzzy search query based on the given search query string.
	 * @param searchQuery - The search query string to generate the fuzzy search query from.
	 * @returns The generated fuzzy search query.
	 */
	const getFuzzySearchQuery = (searchQuery: string) => {
		return !searchQuery // if empty string, just return it
			? ''
			: searchQuery
					.trim()
					.toLowerCase()
					.split(' ')
					.map((word) => `${word}~${FUZZY_DISTANCE}`)
					.join(' ');
	};

	/**
	 * Finds feed items by reference in the reading history page.
	 * @param refs - The references to search for.
	 * @param commands - The feed items to search through.
	 * @returns The found feed items.
	 */
	const findCommandsByRef = (refs: lunr.Index.Result[], commands: Command[]) => {
		try {
			return refs
				.map((item) => {
					return commands.find((i) => i.name === item.ref);
				})
				.filter((item): item is Command => item !== undefined);
		} catch (error) {
			logger.error('Error in findCommandsByRef:', error);
			return [];
		}
	};
</script>

<script lang="ts">
	import type {
		FeedItemSearchSuggestion,
		InstrumentWithExchange,
		PublisherSearchSuggestion,
		TagSearchSuggestion
	} from '@tickrr/trpc/types';

	import { goto } from '$app/navigation';
	import { getDrawerStore, getModalStore } from '@skeletonlabs/skeleton';
	import { logger } from '@tickrr/lib/logger';
	import { calculateTimeSince } from '@tickrr/lib/utils';
	import lunr from 'lunr';

	import type { Command, CommandRegistry } from './CommandMenu.svelte';

	import { getStores } from '../../../../../stores';
	import { getFeedItemImagePath } from '../../../../../utils';
	import Avatar from '../../../elements/images/TwicAvatar.svelte';
	import TwicFeedItemImage from '../../../elements/images/TwicFeedItemImage.svelte';
	import EmptyState from '../../../elements/misc/EmptyState.svelte';
	import LWMiniChart from '../../../modules/charts/LWMiniChart.svelte';
	import CMItem, { COMMAND_MENU_ITEM_CLASS } from './CMItem.svelte';
	import CMItemGroup from './CMItemGroup.svelte';

	const drawerStore = getDrawerStore();
	const modalStore = getModalStore();
	const { isExtensionStore, isMobileStore, searchHistoryStore } = getStores([
		'isExtensionStore',
		'isMobileStore',
		'searchHistoryStore'
	]);

	export let searchQuery: string;
	export let symbols: InstrumentWithExchange[];
	export let feedItems: FeedItemSearchSuggestion[];
	export let publishers: PublisherSearchSuggestion[];
	export let tags: TagSearchSuggestion[];
	export let commandRegistry: CommandRegistry;

	// Note: We only track history for the current search query.
	const handleCurrentQueryClick = () => {
		searchHistoryStore.add({ query: searchQuery, timestamp: Date.now() });
		goto(`/search/${searchQuery}`);
	};

	// const handleItemClick = (e: SearchSuggestionEvent) => {
	// 	goto(e.redirectUrl);
	// };

	// const handleHistoryItemClick = (q: string) => {
	// 	goto(`/search/${q}`);
	// };

	$: fuzzySearchQuery = getFuzzySearchQuery(searchQuery);

	$: actionIdx = (() => {
		try {
			return lunr(function () {
				this.ref('name');
				this.field('description');

				const list = commandRegistry.actions;
				list.forEach((doc) => {
					this.add(doc);
				}, this);
			});
		} catch (error) {
			logger.error('Error creating action index:', error);
			return null;
		}
	})();

	$: foundActionRefs = (() => {
		try {
			return actionIdx?.search(fuzzySearchQuery) ?? [];
		} catch (error) {
			logger.error('Error searching action index:', error);
			return [];
		}
	})();

	$: filteredActions = (() => {
		try {
			return searchQuery !== ''
				? findCommandsByRef(foundActionRefs, commandRegistry.actions)
				: commandRegistry.actions;
		} catch (error) {
			logger.error('Error filtering actions:', error);
			return [];
		}
	})();

	$: pageIdx = (() => {
		try {
			return lunr(function () {
				this.ref('name');
				this.field('description');
				const list = commandRegistry.pages;
				list.forEach((doc) => {
					this.add(doc);
				}, this);
			});
		} catch (error) {
			logger.error('Error creating page index:', error);
			return null;
		}
	})();

	$: foundPageRefs = (() => {
		try {
			return pageIdx?.search(fuzzySearchQuery) ?? [];
		} catch (error) {
			logger.error('Error searching page index:', error);
			return [];
		}
	})();

	$: filteredPages = (() => {
		try {
			return searchQuery !== ''
				? findCommandsByRef(foundPageRefs, commandRegistry.pages)
				: commandRegistry.pages;
		} catch (error) {
			logger.error('Error filtering pages:', error);
			return [];
		}
	})();

	$: isNoSearchResults =
		symbols.length === 0 &&
		publishers.length === 0 &&
		feedItems.length === 0 &&
		tags.length === 0;
	$: isNoFilteredResults = filteredActions.length === 0 && filteredPages.length === 0;
</script>

<div data-testid="command-menu__results-container" class="space-y-2">
	<slot name="lead"><!-- optional fallback --></slot>

	{#if searchQuery === ''}
		<!-- DEFAULT SYMBOL CHARTS -->
		<CMItemGroup
			listClasses="px-2 gap-x-2 flex overflow-x-scroll"
			sectionTitle="Symbols"
			testid="command-menu-items__symbols"
		>
			{#each ['BTC/USD', 'TSLA', 'AAPL'] as symbol, idx (idx)}
				<li class="w-[240px] shrink-0">
					<!-- Key is needed to force script to re-run/re-load TradingView widget ea. time query changes -->
					{#key symbol}
						<LWMiniChart classes={COMMAND_MENU_ITEM_CLASS} {symbol} />
					{/key}
				</li>
			{/each}
		</CMItemGroup>

		<!-- SEARCH HISTORY -->
		{#if $searchHistoryStore.length > 0}
			<CMItemGroup sectionTitle="History" testid="command-menu-items__search-history">
				{#each $searchHistoryStore.slice(0, MAX_HISTORY_ITEMS - 1) as shItem (shItem.query + shItem.timestamp)}
					<CMItem icon="solar:clock-circle-linear" url={`/search/${shItem.query}`}>
						<svelte:fragment slot="value">
							{shItem.query}
						</svelte:fragment>
						<svelte:fragment slot="trail">
							<span class="text-sm">
								{calculateTimeSince(new Date(shItem.timestamp))}
							</span>
						</svelte:fragment>
					</CMItem>
				{/each}
			</CMItemGroup>
		{/if}
	{:else}
		<!-- CURRENT SEARCH VALUE -->
		<ul class="list mb-3 px-3 rounded-token">
			<CMItem
				classes="rounded-token !py-4 btn bg-component hover:bg-component-hover"
				on:click={handleCurrentQueryClick}
			>
				<svelte:fragment slot="value">
					{searchQuery}
				</svelte:fragment>
			</CMItem>
		</ul>

		<!-- EMPTY STATE -->
		{#if isNoSearchResults && isNoFilteredResults}
			<EmptyState
				icon="solar:magnifer-outline"
				classes="py-12"
				description="We were't able to drum up any suggestions based on what you've typed, but please click Enter to see what you can find!"
				title="No suggestions"
			/>
		{/if}
	{/if}

	<!-- Symbols -->
	{#if symbols.length > 0}
		<CMItemGroup
			listClasses="px-2 gap-x-2 flex overflow-x-scroll"
			sectionTitle="Symbols"
			testid="command-menu-items__symbols"
		>
			{#each symbols as suggestion, idx (idx)}
				<li class="w-[240px] shrink-0">
					<!-- Key is needed to force script to re-run/re-load TradingView widget ea. time query changes -->
					{#key suggestion.symbol}
						<LWMiniChart classes={COMMAND_MENU_ITEM_CLASS} symbol={suggestion.symbol} />
					{/key}
				</li>
			{:else}
				<p class="px-4">Nothing found.</p>
			{/each}
		</CMItemGroup>
	{/if}

	<!-- Publishers -->
	{#if publishers.length > 0}
		<CMItemGroup sectionTitle="News Sources" testid="command-menu-items__publishers">
			{#each publishers as publisher (publisher.username)}
				<CMItem url={`/publisher/${publisher.username}`}>
					<svelte:fragment slot="value">
						{publisher.name}
					</svelte:fragment>
					<svelte:fragment slot="img">
						<Avatar
							classes="inline mr-2"
							height="h-4"
							path={publisher.avatar_url}
							width="w-4"
						/>
					</svelte:fragment>
				</CMItem>
			{:else}
				<p class="px-4">Nothing found.</p>
			{/each}
		</CMItemGroup>
	{/if}

	<!-- Feed Items -->
	{#if feedItems.length > 0}
		<CMItemGroup sectionTitle="Articles" testid="command-menu-items__articles">
			{#each feedItems as fi (fi.id)}
				<CMItem icon="solar:document-text-outline" url="/i/{fi.id}">
					<svelte:fragment slot="value">
						<b class="font-bold">
							{fi.title}
						</b>
					</svelte:fragment>
					<svelte:fragment slot="secondary-value">
						@{fi.publisher_username}
					</svelte:fragment>
					<svelte:fragment slot="img">
						<!-- <Avatar classes="inline mr-2" height="h-4" path={fi.img} width="w-4" /> -->
						<TwicFeedItemImage
							alt={fi.title}
							classes="overflow-hidden shrink-0 mr-2"
							height="h-fit"
							path={getFeedItemImagePath(fi)}
							rounded="rounded-md"
							title={fi.title}
							width="w-16"
						/>
					</svelte:fragment>
				</CMItem>
			{:else}
				<p class="px-4">Nothing found.</p>
			{/each}
		</CMItemGroup>
	{/if}

	<!-- Tags -->
	{#if tags.length > 0}
		<CMItemGroup sectionTitle="Tags" testid="command-menu-items__tags">
			{#each tags as tag (tag.value)}
				<CMItem
					icon="solar:tag-horizontal-outline"
					url={`/tag/${encodeURIComponent(tag.value)}`}
				>
					<svelte:fragment slot="value">
						{tag.value}
					</svelte:fragment>
				</CMItem>
			{:else}
				<p class="px-4">Nothing found.</p>
			{/each}
		</CMItemGroup>
	{/if}

	<!-- Actions -->
	{#if filteredActions.length > 0}
		<CMItemGroup sectionTitle="Actions" testid="command-menu-items__actions">
			{#each filteredActions as command (command.name)}
				<CMItem
					icon={command.icon}
					url={command.url}
					on:click={command.handler
						? () => {
								if (!command.handler) return;
								command.handler({
									drawerStore,
									isExtension: $isExtensionStore,
									isMobile: $isMobileStore,
									modalStore
								});
							}
						: undefined}
				>
					<svelte:fragment slot="value">
						<b class="text-surface-50">{command.name}</b>
					</svelte:fragment>
					<svelte:fragment slot="trail">
						<span class="">
							{command.description}
						</span>
					</svelte:fragment>
				</CMItem>
			{:else}
				<p class="px-4">Nothing found.</p>
			{/each}
		</CMItemGroup>
	{/if}

	<!-- Pages -->
	{#if filteredPages.length > 0}
		<CMItemGroup sectionTitle="Pages" testid="command-menu-items__pages">
			{#each filteredPages as page (page.name)}
				<CMItem icon={page.icon} url={page.url}>
					<svelte:fragment slot="value">
						<b>{page.name}</b>
					</svelte:fragment>
					<svelte:fragment slot="trail">
						<span class="">
							{page.description}
						</span>
					</svelte:fragment>
				</CMItem>
			{:else}
				<p class="px-4">Nothing found.</p>
			{/each}
		</CMItemGroup>
	{/if}

	<slot name="trail"><!-- optional fallback --></slot>
</div>
