import type { ModalSettings } from '@skeletonlabs/skeleton';

import { get } from 'svelte/store';

import type { ModalStore } from '../../../../types';

import CommandMenuModal from './_internal/CommandMenu.svelte';

const COMMAND_MENU_MODAL_TITLE = 'command_menu';

const commandMenuModalSettings: ModalSettings = {
	component: {
		ref: CommandMenuModal
	},
	title: COMMAND_MENU_MODAL_TITLE,
	type: 'component'
};

export const openCommandMenu = ({ modalStore }: { modalStore: ModalStore }) => {
	modalStore.set([
		{
			...commandMenuModalSettings,
			// When position is passed, it overrides ALL of the classes we set to position in `MyModal.svelte`
			position:
				'relative !overflow-y-visible items-start max-tablet:p-0 tablet:p-4 tablet:pt-[68px]'
		}
	]);
};

/**
 * Toggles the command menu.
 *
 * @param modalStore - The modal store instance.
 */
export const toggleCommandMenu = ({ modalStore }: { modalStore: ModalStore }) => {
	const currentModalQuery = get(modalStore);
	const currentModal = currentModalQuery ? currentModalQuery[0] : null;
	const isCommandMenuOpen = currentModal?.title === COMMAND_MENU_MODAL_TITLE;

	if (isCommandMenuOpen) {
		modalStore.set([]);
		return;
	}

	openCommandMenu({ modalStore });
};
