<script lang="ts">
	import { tick } from 'svelte';

	import { focusOnMount } from '../../../../actions';

	export let value: string = '';
	export let inputEl: HTMLInputElement | null = null;
	export let isInputFocused: boolean = false;
	export let shouldFocusOnMount: boolean = false;
	export let classes: string = '';
	export let inputClasses: string = '';
	export let placeholders = {
		focused: 'Search articles, stocks, & more',
		unfocused: 'Search...'
	};

	let placeholder = placeholders.unfocused;

	const handleInputFocus = () => {
		isInputFocused = true;
		placeholder = placeholders.focused;
	};

	const handleInputBlur = async () => {
		placeholder = placeholders.unfocused;
		isInputFocused = false;
		await tick();
	};
</script>

<!-- Input -->
<div
	class="input-group relative h-full min-h-[2.75rem] grid-cols-[auto_1fr_auto] !border-0 text-sm duration-0 rounded-token focus-within:!ring-primary-500 {classes}"
>
	<div class="flex items-center justify-center">
		<iconify-icon icon={isInputFocused ? 'solar:magnifer-bold' : 'solar:magnifer-outline'} />
	</div>

	<input
		bind:this={inputEl}
		autocomplete="off"
		data-testid="search-combo-box__input"
		{placeholder}
		type="text"
		class="w-full !pl-0 !outline-0 placeholder:text-surface-100 {inputClasses}"
		bind:value
		on:focus={handleInputFocus}
		on:blur={handleInputBlur}
		on:focus
		on:blur
		on:input
		on:keydown
		use:focusOnMount={{ enabled: shouldFocusOnMount }}
	/>

	<slot name="trail" />
</div>
