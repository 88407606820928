<!--
	@component

	SIDEBAR BUTTON

	@prop name - Button name.
	@prop isDisplayLabel - Whether or not to display the button label.
	@prop href - Button href.
	@prop icon - Button icon.
	@prop arialabel - Button aria-label.
	@prop isActive - Whether or not the button is active.
	@prop isOpenNewTab - Whether or not the button should open in a new tab.
	@prop isHidden - Whether or not the button is hidden.
	@prop type - Button type.
	@prop classes - Button classes.
	@prop iconClasses - Icon classes.
	@prop tooltipContent - Button tooltip content.
-->
<script lang="ts">
	import type { CssClasses } from '@skeletonlabs/skeleton';

	import { page } from '$app/stores';
	import _ from 'lodash';

	// Props
	export let name: string;
	export let isExpanded: boolean;
	export let href: string | undefined = undefined;

	export let icon: string | undefined = undefined;
	export let arialabel: string | undefined = undefined;
	export let isActive: boolean = false;
	export let isOpenNewTab: boolean = false;
	export let isHidden: boolean = false;
	export let type: 'button' | 'reset' | 'submit' = 'button';
	export let classes: CssClasses = '';
	export let iconClasses: CssClasses = '';
	export let tooltipContent: string = name;

	$: kebabName = _.kebabCase(name);
	$: isLink = !!href;
	$: isActive = !!(href && $page.url.pathname.includes(href));
</script>

<!--
Keying to the state of the isSidebarExpandedStore will make the fade-in transition
re-run ea. time the sidebar is expanded/collapsed (rather than only on mount).
-->
<!-- eslint-disable-next-line svelte/valid-compile -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<svelte:element
	this={isLink ? 'a' : 'button'}
	id={kebabName}
	name="sidebar-button"
	aria-label={arialabel}
	aria-pressed={isActive}
	data-testid="sidebar__{kebabName}-button"
	data-tippy-content={tooltipContent}
	href={isLink ? href : undefined}
	rel={isLink && isOpenNewTab ? 'noopener noreferrer' : undefined}
	tabindex={isLink ? -1 : 0}
	target={isLink && isOpenNewTab ? '_blank' : undefined}
	type={isLink ? undefined : type}
	class="
		btn
		my-0
		w-full
		justify-start
		rounded-lg
		border
		border-transparent
		px-2
		text-surface-300
		!duration-0
		hover:bg-component-hover
		hover:text-surface-50
		[&>span]:text-left
		{isExpanded ? 'py-1' : 'py-2'}
		{isActive ? 'bg-component-active !text-primary-500' : ''}
		{classes}"
	class:!hidden={isHidden}
	on:click
>
	<!-- ICON -->
	<iconify-icon inline {icon} height="1em" width="auto" class={iconClasses} />

	<!-- CONTENT -->
	<slot>
		{#if isExpanded}
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			<span>{@html name}</span>
			<slot name="label-trail"><!-- optional fallback --></slot>
		{:else}
			<!-- eslint-disable-next-line svelte/no-at-html-tags -->
			<span class="sr-only">{@html name}</span>
		{/if}
	</slot>
</svelte:element>
