<script lang="ts">
	import { page } from '$app/stores';
	import { createQuery } from '@tanstack/svelte-query';
	import { trpc } from '@tickrr/trpc/client';

	export let symbol: string;

	$: query = createQuery({
		cacheTime: 3000 * 60 * 5, // 3 minutes
		queryFn: async () => {
			return await trpc($page).ticker.fetchQuote.query({
				symbol
			});
		},
		queryKey: ['symbol:quote', symbol],
		staleTime: 3000 * 60 * 5 // 3 minutes
	});
</script>

<slot isError={$query.isError} isLoading={$query.isLoading} quote={$query.data} />
