<script lang="ts">
	import { page } from '$app/stores';
	import { getDrawerStore, getModalStore } from '@skeletonlabs/skeleton';

	import { getStores } from '../../../stores';
	import SearchInput from '../elements/inputs/SearchInput.svelte';
	import { toggleCommandMenu } from './command-menu/index.ts';
	import { openCtaSubscribeModal } from './cta-subscribe/index.js';

	const drawerStore = getDrawerStore();
	const modalStore = getModalStore();
	const { isMacintoshStore } = getStores(['isMacintoshStore']);

	export let classes: string = 'w-full';

	function handleClick(e: MouseEvent) {
		e.preventDefault();
		e.stopPropagation();

		const isSubscribed =
			$page.data.profile && $page.data.profile.active_subscriptions.length > 0;
		if (!isSubscribed) {
			openCtaSubscribeModal(modalStore);
			return;
		}

		drawerStore.close();
		toggleCommandMenu({ modalStore });
	}
</script>

<button
	data-testid="command-menu-trigger"
	data-tippy-content="Open command menu"
	type="button"
	class="overflow-hidden border border-base rounded-token hover:border-hover {classes}"
	on:click={handleClick}
>
	<slot>
		<SearchInput classes="w-full pointer-events-none" inputClasses="!text-xs">
			<div slot="trail" class="absolute right-0 top-1/2 flex h-6 -translate-y-1/2 gap-x-1">
				{#if $isMacintoshStore}
					<kbd class="h-5">
						<iconify-icon icon="mdi:apple-keyboard-command" />
					</kbd>
				{:else}
					<kbd>CTRL</kbd>
				{/if}
				<span class="inline-flex -translate-y-[1px] items-center">+</span>
				<kbd class="h-5 font-normal">K</kbd>
			</div>
		</SearchInput>
	</slot>
</button>
