import type { ModalSettings } from '@skeletonlabs/skeleton';

import type { ModalStore } from '../../../../types';

import FeedbackModal from './_internal/FeedbackModal.svelte';

const feedbackModalSettings: ModalSettings = {
	backdropClasses: '!backdrop-blur-lg',
	component: {
		ref: FeedbackModal
	},
	type: 'component'
};

export const openFeedbackModal = (modalStore: ModalStore) => {
	modalStore.trigger({ ...feedbackModalSettings });
};
