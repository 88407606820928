<script lang="ts">
	import { ProgressRadial } from '@skeletonlabs/skeleton';

	import { flashOnUpdate } from '../../../../../../actions';
	import { InstrumentLogo } from '../../../../elements';
	import { lwFormat } from '../../../charts/common.ts';
	import InstrumentQuote from '../../../markets/InstrumentQuote.svelte';
	import RealTimePriceProvider, {
		type RealTimePriceEvent
	} from '../../../markets/RealTimePriceProvider.svelte';

	export let symbol: string;

	let realTimePriceEvent: RealTimePriceEvent;

	function handlePriceEvent(event: RealTimePriceEvent) {
		realTimePriceEvent = event;
	}
</script>

<RealTimePriceProvider onPriceEvent={handlePriceEvent} {symbol}>
	<InstrumentQuote {symbol} let:isLoading let:quote>
		{@const change = quote?.change}
		{@const close = quote?.close}
		<a
			href="/charts/{symbol}"
			class="btn
			my-0
			flex
			w-full
			justify-between
			rounded-lg
			border
			border-transparent
			px-2
			py-1
			text-xs
			font-bold
			text-surface-300
			!duration-0
			hover:bg-component-hover
			hover:text-surface-50
			[&>span]:text-left
			"
		>
			<div class="flex items-center gap-x-2">
				<InstrumentLogo height="h-4" rounded="rounded-full" {symbol} width="w-4" />
				<span>
					{symbol}
				</span>
			</div>

			<div class="flex items-center gap-x-1">
				{#if isLoading}
					<span>
						<ProgressRadial height="h-4" width="w-4" />
					</span>
				{:else if quote}
					{#key realTimePriceEvent?.status === 'ok' && realTimePriceEvent?.price}
						<span
							class:text-error-500={change && change < 0}
							class:text-success-500={change && change > 0}
							use:flashOnUpdate={{
								color: 'rgb(var(--color-surface-50))',
								duration: 2000
							}}
						>
							{lwFormat(
								'price',
								realTimePriceEvent?.status === 'ok'
									? realTimePriceEvent?.price
									: close
							)}
						</span>
					{/key}
				{:else}
					<span> $0.00 </span>
				{/if}

				{#if change && change > 0}
					<iconify-icon
						icon="solar:alt-arrow-up-bold"
						height="16px"
						class="text-primary-500"
					/>
				{:else if change && change < 0}
					<iconify-icon
						icon="solar:alt-arrow-down-bold"
						height="16px"
						class="text-error-500"
					/>
				{/if}
			</div>
		</a>
	</InstrumentQuote>
</RealTimePriceProvider>
