<script context="module" lang="ts">
	const SEARCH_INPUT_DEBOUNCE_IN_MS = 500;

	type BaseCommand = {
		description: string;
		icon: string;
		name: string;
	};

	type ActionCommand = BaseCommand & {
		handler?: (props: {
			drawerStore: DrawerStore;
			isExtension: boolean;
			isMobile: boolean;
			modalStore: ModalStore;
		}) => void;
		url?: never;
	};

	type PageCommand = BaseCommand & {
		handler?: never;
		url: string;
	};

	export type Command = ActionCommand | PageCommand;

	export type CommandRegistry = {
		actions: (ActionCommand | PageCommand)[];
		pages: PageCommand[];
	};

	const COMMAND_REGISTRY: CommandRegistry = {
		actions: [
			{
				description: 'Manage Account',
				handler: () => {
					if (window.Clerk?.user) {
						window.Clerk?.openUserProfile();
					} else {
						goto('/signin');
					}
				},
				icon: 'solar:settings-linear',
				name: 'Account'
			},
			// {
			// 	description: 'Submit new link to be displayed in the feed',
			// 	handler: openSubmitArticleComponent,
			// 	icon: 'solar:link-square-linear',
			// 	name: 'Submit Article'
			// },
			{
				description: 'Navigate to Profile',
				icon: 'solar:user-circle-linear',
				name: 'View Profile',
				url: '/profile'
			},
			// {
			// 	description: 'Create a new post',
			// 	handler: openNewPostComponent,
			// 	icon: 'solar:pen-new-square-linear',
			// 	name: 'Create post'
			// },
			{
				description: 'Search symbols, news, and more',
				icon: 'solar:magnifer-outline',
				name: 'Search',
				url: '/search'
			}
		],
		pages: [
			{
				description: 'Navigate to Home page',
				icon: 'fluent:home-24-regular',
				name: 'Home',
				url: '/home'
			},
			{
				description: 'Navigate to Charts page',
				icon: 'tabler:chart-line',
				name: 'Charts',
				url: '/charts'
			},
			{
				description: 'Navigate to Markets page',
				icon: 'solar:chart-2-bold',
				name: 'Markets',
				url: '/markets'
			},
			{
				description: 'Navigate to Screener page',
				icon: 'solar:target-outline',
				name: 'Screener',
				url: '/screener'
			},
			{
				description: 'Navigate to News page',
				icon: 'solar:documents-outline',
				name: 'News',
				url: '/news'
			},
			{
				description: 'Navigate to Forums page',
				icon: 'solar:list-outline',
				name: 'Forums',
				url: '/posts'
			},
			{
				description: 'Navigate to Alerts page',
				icon: 'solar:bell-linear',
				name: 'Alerts',
				url: '/alerts'
			},
			{
				description: 'Navigate to Notifications page',
				icon: 'solar:notification-unread-linear',
				name: 'Notifications',
				url: '/notifications'
			},
			{
				description: 'Navigate to Watchlist',
				icon: 'solar:eye-outline',
				name: 'Watchlist',
				url: '/watchlist'
			},
			{
				description: 'Navigate to Bookmarks',
				icon: 'solar:bookmark-outline',
				name: 'Bookmarks',
				url: '/bookmarks'
			},
			{
				description: 'Navigate to Changelog',
				icon: 'solar:code-file-linear',
				name: 'Changelog',
				url: '/changelog'
			}
		]
	};
</script>

<script lang="ts">
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import { type DrawerStore, type ModalStore, getModalStore } from '@skeletonlabs/skeleton';
	import { createQuery } from '@tanstack/svelte-query';
	import { logger } from '@tickrr/lib/logger';
	import { trpc } from '@tickrr/trpc/client';
	import _ from 'lodash';
	import { afterUpdate, onDestroy, onMount } from 'svelte';

	import { COMMAND_MENU_ITEM_CLASS } from './CMItem.svelte';
	import CMItemGroups from './CMItemGroups.svelte';

	const modalStore = getModalStore();

	let searchQuery: string = '';
	let menuEl: HTMLDivElement;
	let inputEl: HTMLInputElement;
	let commandMenuItems: HTMLButtonElement[] = [];
	let currentIdx: number = -1;

	const query = createQuery({
		enabled: false, // Results are manually re-fetched.
		onError: () => {
			logger.error('Command menu: Error fetching search suggestions.');
		},
		queryFn: async () => {
			const results = await trpc($page).search.searchSuggestions.query({
				query: searchQuery.trim().toLowerCase()
			});
			return results;
		},
		queryKey: ['command-menu-suggestions', searchQuery]
	});

	const _updateSearchSuggestions = async () => {
		await $query.refetch();
	};

	const updateSearchSuggestions = _.throttle(
		_updateSearchSuggestions,
		SEARCH_INPUT_DEBOUNCE_IN_MS,
		{
			leading: true,
			trailing: true
		}
	);

	const updateCommandMenuItemList = () => {
		commandMenuItems = Array.from(
			menuEl.getElementsByClassName(COMMAND_MENU_ITEM_CLASS)
		) as HTMLButtonElement[];
	};

	function navigateMenu(direction: -1 | 1) {
		// If there are no items, none of the below will apply
		if (commandMenuItems.length === 0) return;

		// Calculate new index
		currentIdx += direction;

		// Ensure the new index is within bounds.
		// If at the top of the list, remain there.
		// Likewise, if at the bottom, remain there.
		if (currentIdx < 0) {
			currentIdx = 0;
		} else if (currentIdx >= commandMenuItems.length) {
			currentIdx = commandMenuItems.length - 1;
		}

		// Add the selection to the new item
		const currentItem = commandMenuItems[currentIdx];
		currentItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		currentItem.focus();
	}

	const handleKeyDown = (e: KeyboardEvent) => {
		// Close the dropdown if the user presses the escape key.
		if (e.key === 'Escape' || ((e.metaKey || e.ctrlKey) && e.key === 'k')) {
			e.preventDefault();
			modalStore.close();
		} else if (e.key === 'Enter') {
			// If the user presses Enter, navigate to the selected item.
			if (commandMenuItems.length > 0) {
				commandMenuItems[0].click();
				return;
			} else {
				logger.error('Command menu: No results found.');
			}
		} else if (e.key === 'ArrowUp') {
			e.preventDefault();
			navigateMenu(-1);
		} else if (e.key === 'ArrowDown') {
			e.preventDefault();
			navigateMenu(1);
		} else if (document.activeElement !== inputEl) {
			// If input is not focused, focus it then take appropriate action.
			e.preventDefault();
			inputEl.focus();

			/**
			 * // TODO: Here, in future, we may want to reset `currentIdx` to -1.
			 *
			 * Currently, if the user moves back to the input by hitting one of the
			 * conditionals below, then back to the list, they will resume position where
			 * they left off, which can be disorienting.
			 */

			// If the user pressed an arrow key, move the text cursor appropriately.
			if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
				inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
			} else if (e.key === 'Backspace') {
				searchQuery = searchQuery.slice(0, -1);
			} else if (e.key === 'Enter') {
				// If the user presses Enter, navigate to the selected item.
				if (currentIdx >= 0) {
					commandMenuItems[currentIdx].click();
				}
			} else if (e.key.length === 1) {
				// If the user presses a key, add it to the search query.
				searchQuery += e.key;
			} else {
				// Do default.
			}
		} else {
			// Do default.
		}
	};

	const attachKeyboardHandler = () => {
		window.addEventListener('keydown', handleKeyDown);
	};

	const removeKeyboardHandler = () => {
		window.removeEventListener('keydown', handleKeyDown);
	};

	onMount(() => {
		attachKeyboardHandler();
		updateCommandMenuItemList();
	});

	// We update the list after each render, rather than at the time the
	// user navigates the menu, to reduce latency.
	afterUpdate(() => {
		updateCommandMenuItemList();
	});

	// Clear the search query when the user closes the CommandMenu.
	// Otherwise, the next time the user opens during this session,
	// the results for the previous search query will be displayed.
	onDestroy(() => {
		$query.remove();
		removeKeyboardHandler();
	});
</script>

<div
	bind:this={menuEl}
	id="command-menu"
	data-testid="command-menu"
	class="w-full max-w-3xl border border-base bg-surface-800 tablet:rounded-container-token"
>
	<search data-testid="command-menu__search" title="command-menu__serach" class="w-full">
		<form id="command-menu__form" class="w-full" on:submit|preventDefault>
			<!-- INPUT GROUP -->
			<div class="flex p-2">
				<!-- INPUT -->
				<input
					bind:this={inputEl}
					data-testid="command-menu__search-input"
					placeholder="What would you like to do?"
					type="text"
					class="input w-full !border-0 !bg-transparent !outline-0 !ring-0"
					bind:value={searchQuery}
					on:input={updateSearchSuggestions}
				/>

				<!-- CLOSE BUTTON -->
				<div class="flex flex-col justify-center">
					<button
						data-testid="modal-close-button"
						type="button"
						class="btn-icon border border-base rounded-token hover:border-base"
						on:click={() => modalStore.clear()}
					>
						<iconify-icon icon="tabler:x"></iconify-icon>
					</button>
				</div>
			</div>

			<!-- SEARCH DROPDOWN -->
			<div
				data-testid="command-menu__search-results"
				class="overflow-scroll border-t border-base py-4 max-tablet:h-screen tablet:h-fit tablet:max-h-[40rem]"
			>
				<!-- SUGGESTIONS, ETC. -->
				<CMItemGroups
					commandRegistry={COMMAND_REGISTRY}
					feedItems={$query.data?.feedItems || []}
					publishers={$query.data?.publishers || []}
					{searchQuery}
					symbols={$query.data?.symbols || []}
					tags={$query.data?.tags || []}
				>
					<svelte:fragment slot="lead"></svelte:fragment>
				</CMItemGroups>
			</div>
		</form>
	</search>
</div>
