<!-- 
	@component
	
	SIDEBAR
	
	@prop isMobileDrawer - Whether or not this is being displayed in a mobile drawer.
	@prop isExpanded - Whether or not to display expanded contents.
-->
<script lang="ts">
	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import tippy, { createSingleton } from 'tippy.js';

	import { SignedIn, SignedOut } from '../../../../../../auth';
	import CommandMenuTrigger from '../../../../modals/CommandMenuTrigger.svelte';
	import CTASubscribeCard from '../../../marketing/CTASubscribeCard.svelte';
	import AvatarDropdown from '../../AvatarDropdown.svelte';
	import BottomButtonGroup from './BottomButtonGroup.svelte';
	import SidebarButton from './Button.svelte';
	import ButtonGroup from './ButtonGroup.svelte';
	import { getSidebarButtonData } from './buttons.ts';

	const sidebarButtons = getSidebarButtonData();

	// Props...
	/**
	 * This will not always correspond to $isMobile, so we set it manually.
	 */
	export let isMobileDrawer: boolean;
	/**
	 * This may not always correspond to $isPwa, so we set it manually.
	 */
	export let isPwa: boolean;
	/**
	 * This doesn't always correspond to the viewport size directly, so we set it manually.
	 */
	export let isSidebarExpanded: boolean;

	let container: HTMLElement;

	// $: getIsActive = (url?: string) => {
	// 	if (!url) return false;
	// 	const fullPath = $page.url.pathname + $page.url.search;
	// 	console.log('fullPath', fullPath);
	// 	console.log('url', url);
	// 	return fullPath.includes(url);
	// };

	// Add tooltips, using a singleton instance.
	// The tooltip will wait an initial 250ms delay when the first button is hovered,
	// but then show instantly for all others.
	// For the Desktop sidebar, we wrap this component in a `{#key isSidebarExpanded}` block,
	// so that: (1) transitions work properly, and that (2) the tooltip singleton is recreated
	// when the sidebar is expanded/collapsed.
	onMount(() => {
		const buttons = tippy(container.querySelectorAll('[name="sidebar-button"]'));
		createSingleton(buttons, {
			allowHTML: true,
			arrow: true,
			delay: [250, 250],
			placement: 'right',
			theme: 'light',
			touch: false
		});
	});
</script>

<nav
	bind:this={container}
	data-testid="sidebar"
	class="text-surface-700-200-token relative flex h-full max-h-screen w-full flex-col p-2 max-tablet:overflow-y-scroll"
	in:fade={{ duration: 800 }}
>
	<div class="group tablet:w-full">
		{#if !isSidebarExpanded}
			<SignedIn>
				<div class="mx-auto my-4 h-8 w-8">
					<!-- Note that we check for `isSidebarExpanded` above, so the classes need not be made conditional. -->
					<AvatarDropdown classes="!p-0" trailClasses="hidden" />
				</div>
			</SignedIn>
		{:else}
			<div class="mb-2 space-y-2">
				<!-- 
					USER AVATAR, NAME, & SEARCH BAR
					-->
				<SignedIn>
					<AvatarDropdown classes="w-full" />
					<CommandMenuTrigger classes="w-full !mt-4" />
				</SignedIn>

				<SignedOut>
					<div>
						<CTASubscribeCard />
					</div>
				</SignedOut>
			</div>
		{/if}

		<!--
			SECTIONS
			-->

		<!-- // {
			// 	arialabel: 'Navigate to Home page',
			// 	icon: 'fluent:home-24-regular',
			// 	name: 'Home',
			// 	url: '/home'
			// }, -->
		<SidebarButton
			name="Home"
			icon="fluent:home-24-regular"
			arialabel="Navigate to Home page"
			href="/home"
			isExpanded={isSidebarExpanded}
		/>

		<ButtonGroup
			buttons={sidebarButtons.research}
			{isMobileDrawer}
			{isSidebarExpanded}
			label="Markets"
		/>
		<ButtonGroup
			buttons={sidebarButtons.social}
			{isMobileDrawer}
			{isSidebarExpanded}
			label="Social"
		/>
		<ButtonGroup
			buttons={sidebarButtons.manage}
			{isMobileDrawer}
			{isSidebarExpanded}
			label="Manage"
		/>
	</div>

	<!-- 
		SPACER
		-->
	<div class="relative min-h-[3rem] flex-1">
		<!-- 
			SLOT: SIDEBAR TOGGLE BUTTON (optional, only used for defaultSidebar)
			-->
		<slot name="sidebar-toggle-button" />
	</div>

	<!-- 
		FOOTER
		-->
	<footer class="whitespace-normal break-words" class:max-tablet:pb-12={isPwa}>
		<!--
			SOCIAL BUTTONS
			Displayed at bottom end of sidebar
			-->
		<BottomButtonGroup buttons={sidebarButtons.misc} {isMobileDrawer} {isSidebarExpanded} />
	</footer>
</nav>
